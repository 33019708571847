<template>
  <div id="table-debt" class="table__container">
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
            @click="
              filter.sort_type == 'asc'
                ? (filter.sort_type = 'desc')
                : (filter.sort_type = 'asc')
              " />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input v-model="filter.search" placeholder="Cari nama atau nomor telepon" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mb-1 mt-3" v-if="isLoading">
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table v-else max-height="80vh" :columns="columns" :rows="rows.data" :fixed-header="false" :sort-options="{
      enabled: false,
    }" :pagination-options="{
        enabled: false,
      }">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Utang Active -->
        <span v-if="props.column.field === 'utang_active'">
          {{ props.row.utang_active | formatAmount }}
        </span>
        <!-- Column: Utang Due -->
        <span v-else-if="props.column.field === 'utang_due'">
          {{ props.row.utang_due | formatAmount }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <div v-if="checkPermission('bayar pembayaran utang')">
            <b-button class="bg-success-2 border-8 border-0 px-5 py-1" @click="detailUtang(props.row)">
              Bayar
            </b-button>
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border" style="padding: 0.8rem">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows" :limit="4" align="right" @pagination-change-page="getData" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import {
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BCol,
    BRow,
    BSpinner,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    loading: {
      type: Boolean,
    },
    result: {
      type: Object,
    },
  },
  watch: {
    result(value) {
      this.rows = value;
    },
    loading(value) {
      this.isLoading = value;
    },
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Nama",
          field: "name",
        },
        {
          label: "No.Telepon",
          field: "phone_number",
        },
        // {
        //   label: 'Pemasok',
        //   field: 'name',
        // },
        {
          label: "Sisa Utang Aktif",
          field: "utang_active",
        },
        {
          label: "Utang Jatuh Tempo",
          field: "utang_due",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: {},
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    detailUtang(item) {
      this.$store
        .dispatch("supplier/getDetailUtang", {
          uuid: item.uuid,
        })
        .then((result) => {
          localStorage.setItem("is_piutang", false);
          localStorage.setItem("customer_supplier_uuid", item.uuid);
          this.$store.commit("supplier/setDetailUtang", {
            ...result.data.data,
            ...item,
          });
          this.$store.commit("customer/setIsUtang", false);
          this.$bvModal.show("modal-debt");
          // this.isLoading = false
          // this.rows = result.data.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      queryParams.with_utang = 1;

      this.$store
        .dispatch("supplier/getData", {
          params: queryParams,
        })
        .then((result) => {
          this.isLoading = false;
          this.rows = result.data.data;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  computed: {
    filteredRows() {
      if (this.rows && this.rows.data && this.rows.data.length > 0) {
        return this.rows.data.filter((row) => {
          const isUtangActiveEmpty =
            !row.utang_active || row.utang_active.length === 0;
          const isUtangDueEmpty = !row.utang_due || row.utang_due.length === 0;
          return !isUtangActiveEmpty || !isUtangDueEmpty;
        });
      }
      return [];
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

#table-debt {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
